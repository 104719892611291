@import "antd/dist/antd.css";
.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  transition: all 2s ease-in-out;
  /* user-select: none; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 55px) {
  #fadeshow1 {
    display: none;
  }
}

.draggablecontainerhere > div {
  transform: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-badge-count {
  cursor: pointer;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}

.active {
  animation: MoveUpDown 0.6s linear infinite alternate;
  position: relative;

  /* left: 0; */
  /* top: 0 */
  /* bottom: 0; */
}

.transition {
  transition: width 5s;
}

.floatButton {
  height: 60px;
  width: 60px;
}

.ant-modal {
  padding-bottom: 0px;
}

.ant-modal-content {
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.ant-select-selector {
  min-height: 48px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px;
}

.ant-modal-footer {
  padding: 40px 0px 40px 0px;
  text-align: center;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
  border-width: 2px;
}

.ant-modal-footer > .ant-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  height: 42px;
  width: 92px;
  border-radius: 24px;
  /* color: inherit; */
  /* background-color: inherit; */
  border: 0px;
}

.ant-collapse-extra {
  margin-top: 0px;
}

.site-tag-plus {
  height: "36px";
  width: "100px";
  border: "0px";
  cursor: "pointer";
  border-radius: 18px;
}

.edit-tag {
  height: 32px;
}

.ant-input-affix-wrapper {
  border-radius: 8px;
  height: 48px;
  padding-right: 20px;
}

.ant-input {
  border-radius: 8px;
  background-color: inherit;
  font-weight: inherit;
  transition: none;
}

.ant-tag {
  border: 0px;
  border-radius: 18px;
  height: 36px;
  font-size: 12px;
  font-weight: 500;
  padding: 6px;
}
.ant-tag > .anticon {
  color: inherit;
}

.ant-popover-content {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.ant-dropdown-menu-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.text {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.container {
  display: flex;
  touch-action: none;
  width: 100%;
}

.dropzone {
  flex: 1;
  height: 400px;
}

.dropzone.left {
  margin-right: 10px;
}

.grid-item {
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

/* .ant-breadcrumb-separator {
  align-items: center;
} */

.grid-item-content {
  width: 90px;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  border-radius: 50%;
}

.ant-collapse-extra {
  text-align: center;
  margin-top: 2px;
}

.ant-select-selector {
  border-radius: inherit;
  padding: 10%;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: lightgray;
  color: inherit;
  vertical-align: middle;
  height: 36px;
  border-radius: 18px;
  border: 0px;
}

.ant-select-selection-item-content {
  margin: auto;
}

.ant-select-selection-item-remove {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 12px;
  margin-left: 12px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: inherit;
  background-color: inherit;
}

.ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  border-radius: 0px 10px 10px 0px;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-radius: 0px 10px 10px 0px;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 10px 0px 0px 10px;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 10px 0px 0px 10px;
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-radius: 10px 0px 0px 10px;
}

/* .url-new-input .ant-collapse-content-box {
  padding: 0px;
} */

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-radius: 10px 0px 0px 10px;
}

.anticon-search {
  cursor: alias;
}

/* [data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: auto;
  margin-top: auto;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
} */

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: gray;
}
/* .ant-select-selection {
  background-color: green;
} */

.custom-actions > .ant-collapse-item > .ant-collapse-header {
  display: none;
  height: 0;
  padding: 0;
  margin: 0;
  cursor: alias;
}

/* .ant-collapse-arrow {
  display: none;
} */

.demo-loadmore-list {
  width: 500px;
}

.ant-card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 6px;
}

.ant-card-actions {
  height: 50px;
  background-color: inherit;
  transition: all 3s ease-in-out 3s;
}

.ant-typography-edit {
  animation: fadeIn 0.7s ease-in-out;
}
.ant-card-extra {
  animation: fadeIn 0.7s ease-in-out;
}

.my-search {
  margin: auto;
  width: 100%;
  height: 48px;
  padding-left: 1%;
  /* maxWidth: "600px"; */
  min-width: 0px;
  border-radius: 24px;
}

.my-button {
  height: 48px;
  font-size: 14px;
  font-weight: 600;
  width: 117px;
  line-height: 14px;
  border-radius: 24px;
}

.ant-collapse-item {
  opacity: 1 !important;
  /* background-color: aqua !important; */
  /* background: url('img/red_transparent_background.png'); */
}

section {
  /* margin: 60px auto; */
  padding-top: 0;
}
section.demo {
  margin-top: 30px;
}
section h4 {
  margin: 30px 0 15px 0;
  font-weight: 500;
}

.welcome-visible {
  display: inline-block;
}

.ant-input-affix-wrapper > input.ant-input {
  margin-left: 10px;
  color: inherit;
}

.ant-dropdown-menu-item-active {
  border-radius: inherit;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-select-selection-item {
  margin: auto;
}

input.ant-select-selection-search-input {
  margin-top: 9px !important;
  margin-right: 3px !important;
  margin-left: -3px !important;
  margin-bottom: 9px !important;
}

input[type="checkbox"] {
  opacity: 0;
}

.input[type="radio"] {
  opacity: 0;
}

.ant-radio-inner {
  display: none;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0%;
  margin: 0%;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0px;
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 7px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgray !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: lightgray !important;
  border-radius: 10px !important;
}

@media (max-width: 600px) {
  section.demo {
    border-top: 0;
  }
}
@media (max-width: 660px) {
  .welcome-visible {
    display: none;
  }
}
@media (max-width: 600px) {
  .demo-loadmore-list {
    width: 300px;
  }
}

/* @media (max-height: 90%) {

} */

/* .ant-breadcrumb-link {
  color: #1890ff
}

.ant-breadcrumb-separator {
  color: #1890ff
} */

@keyframes MoveUpDown {
  0% {
    bottom: 0px;
    font-size: 120%;
  }

  100% {
    bottom: 5px;
    font-size: 120%;
  }
}

.fade.in {
  animation-name: fadeIn;
}

.fade.out {
  animation-name: fadeOut;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-48);
  }
  100% {
    opacity: 1;
    transform: translateY(48);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(48);
  }
  100% {
    opacity: 0;
    transform: translateY(48);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
